import { Box } from "@mui/material";
import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import styles from "./styles.module.scss"
const SliderBanner: FC<any> = ({ slides }) => {
  return (
    <Box className={styles._container}>
      <Swiper
        modules={[Autoplay]}
        slidesPerView={1}
        autoplay={{
          delay: 3000
        }}
        className={styles._swiper}
      >
        {slides?.map((slide: any, index: number) => (
          <SwiperSlide key={index}>
            {slide}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export default SliderBanner
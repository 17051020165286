import { Box } from "@mui/material";
import { FC } from "react";
import styles from "./styles.module.scss"

const InfoBanner: FC = () => {
  return (
    <Box className={styles._container}>
      <Box className={styles._content}>
        <h3 className={styles._titleMobile}>¡Haz que tu juego brille en PatiJam 2024!</h3>
        <Box className={styles._imageContainer2}>
          <img src="/images/Mascota.png" alt="mascota patijam" className={styles._image} />
        </Box>
        <Box className={styles._textContainer}>
          <h3 className={styles._title}>¡Haz que tu juego brille en PatiJam 2024!</h3>
          <p className={styles._text}>
            PatiJam 2024 es un evento online ideal para desarrolladores, ilustradores, guionistas y músicos apasionados por la creación de videojuegos. Los participantes podrán reunirse para desarrollar juegos web usando PhaserJS, Unity o Godot, y competir por premios y reconocimiento en la comunidad.
          </p>
          <p className={[styles._text, styles._text2].join(' ')}>
            Los mejores juegos se publicarán en Patilla Games, obteniendo visibilidad ante miles de jugadores. El evento será completamente en línea y se transmitirá en vivo los viernes, sábados y domingos durante la competencia.
          </p>
          <p className={[styles._label].join(' ')}>
            ¡No te lo pierdas!
          </p>
        </Box>
      </Box>
    </Box>
  )
}

export default InfoBanner
import { createSlice } from "@reduxjs/toolkit";
import { changeMenu } from "./action";

const initialState = {
  menuOpen: false,
};

const intermittence = createSlice({
  name: 'intermittence',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeMenu, (state, { payload }: any) => {
      return {
        ...state,
        menuOpen: payload
      };
    })
  },
})

export default intermittence.reducer;

import { Box } from "@mui/material"
import { FC } from "react"
import styles from "./styles.module.scss"

const RequirementsBanner: FC = () => {
  return (
    <Box className={styles._container}>
      <Box className={styles._content}>
        <Box className={styles._imageContainer}>
          <img src="/images/request.png" alt="imagen Requisitos" className={styles._image} />
        </Box>
        <Box className={styles._textContainer}>
          <h3 className={styles._title}>Requisitos</h3>
          <p className={styles._text}>Para tener la oportunidad de ganar premios y ver tu juego publicado en Patilla Games, asegúrate de cumplir con todos los requisitos del evento.</p>
          <p className={styles._text}>Solo necesitas desarrollar un juego alineado con el tema propuesto y seguir las reglas de participación. Al finalizar, tu proyecto será evaluado por expertos en varias categorías, desde creatividad y jugabilidad hasta diseño y sonido. </p>
          <p className={styles._textAction}>¡Descarga ahora los requisitos completos y prepárate para destacar con tu talento!</p>
          <a href="https://drive.google.com/file/d/1K-tv3zSAjn2CNJ8KN8u2Fo8N5s8mQipO/view?usp=sharing" rel="noreferrer" target="_blank" className={styles._button}>
            Descargar
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z" fill="#151519" />
            </svg>
          </a>
        </Box>
      </Box>
    </Box>
  )
}

export default RequirementsBanner

import reducers from './reducers';
import { configureStore } from '@reduxjs/toolkit';


const reducer = (state: any, action: any) => {
  return reducers(state, action);
};

const store: any = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
      inmutableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

type ConfigureStore = ReturnType<typeof  store>;
type StoreGetState = ConfigureStore['getState'];
export type RootState = ReturnType<StoreGetState>;

export default store;

import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import styles from "./styles.module.scss"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import './styles.scss'
const EvaluationSlider: FC = () => {

  const pagination = {
    clickable: true,

    renderBullet: (index: number, className: string) => {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  const slides = useMemo(() => {
    return [
      {
        title: 'Cumplimiento del Tema',
        description: 'El juego debe alinearse e integrar creativamente el tema propuesto.',
        image: '/images/theme.png'
      },
      {
        title: 'Innovación y Creatividad',
        description: 'Se valorará la originalidad en mecánicas, narrativa y presentación.',
        image: '/images/creativity.png'
      },
      {
        title: 'Jugabilidad',
        description: 'Mecánicas fluidas, dificultad equilibrada y experiencia divertida.',
        image: '/images/game.png'
      },
      {
        title: 'Diseño y Arte',
        description: 'Calidad y coherencia estética en personajes, escenarios y animaciones.',
        image: '/images/art.png'
      },
      {
        title: 'Sonido y Música',
        description: 'Sonido y música que mejoren la inmersión del jugador.',
        image: '/images/sound.png'
      },
      {
        title: 'Experiencia de Usuario (UX)',
        description: 'Interfaz intuitiva, menús claros y experiencia fluida.',
        image: '/images/exp.png'
      },
      {
        title: 'Prototipo Funcional',
        description: 'Juego jugable, sin errores importantes y bien estructurado.',
        image: '/images/proto.png'
      },
      {
        title: 'Código',
        description: 'Código eficiente, estable y fácil de mantener o expandir.',
        image: '/images/code.png'
      }
    ]
  }, [])

  return (
    <Box className={styles._container3}>
      <Box className={styles._content3}>
        <Box className={styles._textContainer3}>
          <h3 className={styles._title4}>Puntos a Evaluar</h3>
          <p className={styles._text4}>Cada juego será evaluado en diversas categorías por un panel de expertos. Desde la alineación con el tema propuesto, hasta la jugabilidad, arte, sonido y más, los mejores proyectos destacarán por su innovación y creatividad.</p>
        </Box>
        <Box className={styles._sliderContainer}>
          <Swiper
            pagination={pagination}
            modules={[Pagination, Autoplay]}
            slidesPerView={1}
            autoplay={{
              delay: 5000
            }}
            loop={true}
            className={styles._swiper}
          >
            {slides?.map((slide: any, index: number) => (
              <SwiperSlide key={index} className={styles._slide}>
                <Box className={styles._slider}>
                  <Box className={styles._sliderTextContainer}>
                    <h4 className={styles._titleSlider}>{slide.title}</h4>
                    <p className={styles._textSlider}>{slide.description}</p>
                  </Box>
                  <Box className={styles._imageContainer}>
                    <img src={slide.image} alt="" className={styles._image} />
                  </Box>
                  <p className={styles._textSliderMobile}>{slide.description}</p>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  )
}

export default EvaluationSlider
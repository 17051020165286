
import { useEffect } from 'react';
import { Main } from './pages';
import { BrowserRouter } from 'react-router-dom';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

function App() {

  const checkDomain = () => {
    const win: any = window;

    if (win) {
      const currentDomain = win.location.hostname;
      const mainDomain = 'games.lapatilla.com';
      const reTest = /(games.lapatilla.com)$/i

      if (!currentDomain.match(reTest)) {
        try {
          const url = `https://${mainDomain}${win.location.pathname}`;
          const http = new XMLHttpRequest();
          http.open("GET", url, false);
          http.send();
          if (http.status === 200) {
            win.top.location = url;
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  useEffect(() => {
    checkDomain();
  }, [])


  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  );
}

export default App;

import { FC, useEffect, useMemo, useState } from "react";
import { SliderBanner } from "@/components/Marketing";
import { BigCard, CategoryBanner, Features, Layout } from "@/components";
import { Box } from "@mui/material";
import styles from "./styles.module.scss"
import { FetchService } from "@/utils";
const Home: FC = () => {

  const [games, setGames] = useState<any>([])
  const [categories, setCategories] = useState<any>([])


  const sliders = useMemo(() => {
    return [
      <Box sx={{ width: '100%', height: '100%' }} className={styles._imageContainer}>
        <video autoPlay muted loop src="/images/intro.webm" className={styles._image} />
      </Box>
    ]
  }, [])

  const getGames = async () => {

    try {
      const data = await FetchService('game/paginate', 'POST', { page: 1, limit: 10, query: { feature: true } })
      const featureSlider = data?.result?.map((item: any, index: number) => (
        <BigCard key={index} game={item} />
      ))
      setGames(featureSlider)
    } catch (error) {
      console.log(error)
    }

  }


  const fetchCategories = async () => {
    try {
      const data = await FetchService('category')
      const result = data?.result?.reduce((acc: any, item: any, index: number) => {
        if (index % 2 === 0) acc.push({ ad: true })
        acc.push(item)
        return acc
      }, [])

      setCategories(result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getGames()
    fetchCategories()
  }, [])

  return (
    <div>
      <SliderBanner slides={sliders} />
      <Layout>
        <Features data={games} />
        {
          categories?.map((category: any, index: number) => {
            return category?.ad ? (
              <Box key={index} className={styles._adContainer}>
                <Box sx={{ border: '1px solid #DADADA', color: '#DADADA', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '160px' }}>
                  AD
                </Box>
              </Box>
            ) : (
              <CategoryBanner key={`${category?.name}-${index}`} category={category} />
            )
          })
        }
      </Layout>
    </div>
  )
}

export default Home
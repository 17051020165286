
import ReactDOM from 'react-dom/client';
import '@/styles/globals.scss';
import App from './App';
import { Provider } from 'react-redux';
import store from '@/store'
import TagManager from 'react-gtm-module'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const tagManagerArgs = {
  gtmId: 'GTM-TL3MB5P'
}

TagManager.initialize(tagManagerArgs)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

import axios from 'axios';
import { fallbackRestUrl } from './path';
const API_URL = process.env.REST_URL || fallbackRestUrl;

const FetchService = async (url: any, method = 'GET', variables = {}, auth: any = null, loading: any = true) => {
  try {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    if (auth) headers.Authorization = `Bearer ${auth}`;

    const response = await axios(`${API_URL}/${url}`, {
      headers,
      method: method,
      data: variables,
    });
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      return JSON.stringify(err?.response?.data);
      // throw new Error(JSON.stringify(err?.response?.data) || '');
    }
    throw new Error(JSON.stringify(err?.response?.data) || '');
  }
}

export default FetchService;

import { FC, useMemo } from "react"
import styles from "./styles.module.scss"
import { Box } from "@mui/material"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Navigation, Pagination } from "swiper/modules"
import './styles.scss'

const TechnologyBanner: FC = () => {

  const slides = useMemo(() => {
    return [
      {
        title: 'PhaserJS',
        description: 'Potente framework de desarrollo para crear juegos web en 2D con JavaScript. Ideal para juegos rápidos y ligeros, ofrece herramientas fáciles de usar para manejar animaciones, físicas y gráficos en navegadores. Perfecto para desarrolladores que buscan simplicidad y rendimiento en juegos basados en web.',
        image: '/images/phaser.png'
      },
      {
        title: 'Unity',
        description: 'Uno de los motores más populares y versátiles para crear juegos en 2D, 3D y realidad aumentada o virtual. Su potencia, junto con una extensa biblioteca de recursos y un sólido soporte multiplataforma, lo convierten en una herramienta favorita para proyectos de cualquier escala. Utilizado por grandes estudios y desarrolladores indie, Unity ofrece un entorno completo para la creación de experiencias interactivas de alta calidad.',
        image: '/images/unity.png'
      },
      {
        title: 'Godot',
        description: 'Motor de videojuegos de código abierto que permite desarrollar tanto juegos en 2D como en 3D. Con una interfaz intuitiva y un sistema de nodos flexible, es ideal para crear juegos de cualquier tipo, desde plataformas hasta aventuras complejas. Su comunidad activa y su versatilidad lo hacen una excelente opción tanto para principiantes como profesionales.',
        image: '/images/godot.png'
      }
    ]
  }, [])

  return (
    <Box className={styles._container}>
      <Box className={styles._content}>
        <h3 className={styles._title}>Tecnologías</h3>
        <Swiper className={[styles._swiper, 'secondSwiper'].join(' ')}
          pagination={{
            dynamicBullets: true,
          }}
          slidesPerView={1} autoplay={{ delay: 10000 }}
          loop={true}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}>
          {slides?.map((slide: any, index: number) => (
            <SwiperSlide key={index} className={styles._slideContainer}>
              <Box className={styles._slide}>

                <Box className={styles._textContainer}>
                  <h3 className={styles._titleSlider}>{slide?.title}</h3>
                  <p className={styles._textSlider}>{slide?.description}</p>
                </Box>
                <Box className={styles._imageContainer}>
                  <img src={slide?.image} alt="mascota patijam" className={styles._image} />
                </Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default TechnologyBanner
import { Box } from "@mui/material"
import { FC } from "react"
import styles from "./styles.module.scss"
const InscriptionBanner: FC = () => {
  return (
    <Box className={styles._container}>
      <Box className={styles._content}>
        <Box className={styles._imageContainer}>
          <img src="/images/inscription.png" alt="imagen Requisitos" className={styles._image} />
        </Box>
        <h3 className={styles._title}>¡Inscríbete Ahora en PatiJam 2024!</h3>
        <p className={styles._text}>
          No pierdas la oportunidad de mostrar tu talento y competir por premios increíbles mientras desarrollas tu juego con PhaserJS, Godot o Unity. Únete a creativos de todo el mundo, trabaja en tu proyecto y publica tu juego en <span>Patilla Games</span>
        </p>
        <a href="https://forms.gle/Jr4TEtAcgAMW17Q8A" rel="noreferrer" target="_blank" className={styles._button}>¡Inscríbete hoy!</a>
      </Box>
    </Box>
  )
}

export default InscriptionBanner
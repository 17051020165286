import { Header } from "@/components"
import { MainRouter } from "@/router"
import { Box } from "@mui/material"
import { FC } from "react"
import styles from "./styles.module.scss"

const Main: FC = () => {
  return (
    <Box>
      <Header />
      <Box className={styles._container} sx={{ width: '100%', minHeight: '100vh', display: 'grid' }}>
        <MainRouter />
      </Box>
    </Box>
  )
}

export default Main
import { Box } from "@mui/material";
import { FC } from "react";
import styles from "./styles.module.scss"

const BenefitBanner: FC = () => {
  return (
    <Box className={styles._container2}>
      <Box className={styles._content2}>
        <h3 className={styles._title2}>Beneficios</h3>
        <Box className={styles._itemContainer}>
          <Box className={styles._item}>
            <Box className={styles._itemImageContainer}>
              <img src="/images/1.png" alt="mascota patijam" className={styles._image} />
            </Box>
            <h4 className={styles._title3}>Exposición Global</h4>
            <p className={styles._itemText}>
              Publica tu juego en una plataforma con una audiencia masiva y en crecimiento constante.
            </p>
          </Box>
          <Box className={styles._item}>
            <Box className={styles._itemImageContainer}>
              <img src="/images/2.png" alt="mascota patijam" className={styles._image} />
            </Box>
            <h4 className={styles._title3}>Ingreso por Publicidad</h4>
            <p className={styles._itemText}>
              Recibe el 30% de las ganancias generadas por la publicidad en tu juego.
            </p>
          </Box>
          <Box className={styles._item}>
            <Box className={styles._itemImageContainer}>
              <img src="/images/3.png" alt="mascota patijam" className={styles._image} />
            </Box>
            <h4 className={styles._title3}>Gana Grandes Premios</h4>
            <p className={styles._itemText}>
              Los mejores juegos serán recompensados con increíbles premios y reconocimiento.
            </p>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BenefitBanner
import { FC, useEffect, useMemo } from "react";
import { SliderBanner } from "@/components/Marketing";
import { Box } from "@mui/material";
import styles from "./styles.module.scss"
import { BenefitBanner, EvaluationSlider, InfoBanner, InscriptionBanner, RequirementsBanner, SponsorsSlider, TechnologyBanner } from "./components";
import TagManager from 'react-gtm-module'


const Patijam: FC = () => {

  const sliders = useMemo(() => {
    return [
      <Box sx={{ width: '100%', height: '100%' }} className={styles._imageContainer}>
        <video autoPlay muted loop src="/images/intro.webm" className={styles._imageSlider} />
        <video autoPlay muted loop src="/images/intro-m.webm" className={styles._imageSliderMobile} />
      </Box>
    ]
  }, [])

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'pageview',
        title: 'LaPatilla.com - Games',
      }
    }
    TagManager?.dataLayer(tagManagerArgs)
  }, [])

  return (
    <>
      <SliderBanner slides={sliders} />
      <InfoBanner />
      <BenefitBanner />
      <EvaluationSlider />
      <RequirementsBanner />
      <TechnologyBanner />
      <SponsorsSlider />
      <InscriptionBanner />
    </>
  )
}

export default Patijam